<template>
  <div v-if="article" id="specialbuyview">
    <v-flex xs12 :class="isMobile ? 'box mt-8' : 'box mt-16'">
      <v-btn v-if="!isMobile" icon color="black" @click="back()" class="ml-3">
        <v-icon medium>mdi-arrow-left</v-icon> {{ $t("back") }}
      </v-btn>

      <v-card class="mx-auto mt-3 mb-5" elevation="0">
        <h1 class="text-1 center">{{ article.judul }}</h1>

        <div :class="isMobile ? 'w-90 mx-auto' : 'w-50 mx-auto'">
          <v-img width="100%" :src="article.icon"></v-img>
        </div>

        <v-card-text class="py-0">
          <div
            :class="isMobile ? 'text-2' : 'text-2 w-90 mx-auto'"
            v-html="article.konten"
          ></div>
        </v-card-text>
      </v-card>

      <div :class="isMobile ? 'mx-auto center' : 'w-50 mx-auto center'">
        <div class="voucher-code-section">
          <p class="mb-2">Kode Voucher</p>
          <p class="mb-2 voucher-code">
            <span ref="voucher_code">PUTRAGANTENG567</span>
            <v-icon class="ml-2" medium @click="copy()">
              mdi-content-copy
            </v-icon>
          </p>
        </div>
        <v-btn
          class="mt-5 mx-auto"
          color="black"
          dark
          :width="isMobile ? '100%' : '50%'"
          height="44px"
          rounded-sm
          @click="toPage('Home')"
        >
          <span class="btn-text"> Shop Now </span>
        </v-btn>
      </div>
    </v-flex>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "@/axios";

export default {
  name: "SpecialBuyView",
  computed: {
    ...mapState(["isMobile"]),
  },
  data() {
    return {
      article: null,
    };
  },
  methods: {
    toPage(route) {
      this.$router.push({ name: route });
    },
    copy() {
      navigator.clipboard.writeText(this.$refs.voucher_code.innerHTML);
      
      this.$store.commit("setText", "Text Copied ");
      this.$store.commit("setSnackbar", true);
    },
    getArticle() {
      this.$store.commit("setIsLoading", true);
      axios
        .get("/api/v1/pages/artikel/" + this.$route.params.slug)
        .then((response) => {
          this.article = response.data.data;
          this.setMetaInfo(null, this.article.judul);
        })
        .catch((error) => {
          this.errorHandling(error);
        })
        .finally(() => {
          this.$store.commit("setIsLoading", false);
        });
    },
  },
  mounted() {
    if (this.$route.params.slug) {
      this.getArticle();
    }

    this.$store.commit("setIsHome", false);
    this.$store.commit("setIsMemberPage", true);
    this.$store.commit("setIsLoginPage", false);
    this.$store.commit("setActivePage", "Special Buy");
  },
};
</script>

<style scoped>
.box {
  background-color: #ffffff;
  color: #000000 !important;
  padding: 20px;
  margin-bottom: 2px;
  font-size: 0.8rem !important;
  font-style: normal !important;
}

.text-1 {
  font-size: 18px;
  color: #000000 !important;
  font-weight: 600;
  word-break: normal;
  line-height: 2rem;
  padding: 16px 0 !important;
}

.text-2 {
  font-size: 12px !important;
  text-align: justify;
  text-justify: inter-word;
  padding: 16px 0 !important;
}

.voucher-code-section {
  padding: 6px 12px;
  text-align: center;
  border: 1px solid #000000;
  border-radius: 5px;
}

.voucher-code {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
}

.center {
  text-align: center;
}

.w-90 {
  width: 90%;
}

.w-50 {
  width: 50%;
}
</style>
